import axios from 'axios'
import Encrypt from "@/helpers/encrypt"
import auth from "./authService"
import store from '@/store'

axios.interceptors.request.use(async function(config) {
  const token = await auth.getToken()
  config.withCredentials = true
  config.headers.Authorization = `Bearer ${token}`

  if(!config?.silent) {
    /**
     * แสดง loading
     */
    store.commit('updateLoadingStatus', true)
  }

  return config;
}, function(error) {
  // Do something with request error
  return Promise.reject(error.response.data);
});

axios.interceptors.response.use(
  (response) => {
    store.commit('updateLoadingStatus', false)
    return response;
  },
  (error) => {
    store.commit('updateLoadingStatus', false)
    if(error.response.status === 401)
      return auth.logout()

    return Promise.reject(error.response.data);
  }
);

export default axios
