import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/manage-lotto/daily',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/manage-lotto',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'daily',
              name: 'MarketDashboard',
              component: () => import('@/views/manage-lotto/Dashboard')
            },
            {
              path: 'daily/:lottoDate',
              name: 'MarketDate',
              component: () => import('@/views/manage-lotto/Dashboard')
            },
            {
              path: 'wait-result',
              name: 'WaitResults',
              component: () => import('@/views/manage-lotto/WaitResults')
            },
            {
              path: 'setting',
              name: 'LottoSettings',
              component: () => import('@/views/manage-lotto/Settings')
            },
            {
              path: 'pay-half',
              name: 'PayHalfNumbers',
              component: () => import('@/views/manage-lotto/PayHalfNumbers')
            },
            {
              path: 'close-numbers',
              name: 'CloseNumbers',
              component: () => import('@/views/manage-lotto/CloseNumbers')
            },
            {
              path: 'award',
              name: 'AwardNumbers',
              component: () => import('@/views/manage-lotto/AwardNumbers')
            },
            {
              path: 'pay',
              name: 'PayWinTickets',
              component: () => import('@/views/manage-lotto/PayWinTickets')
            },
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    }
  ]
}

router.beforeEach((to, from, next) => {
  const publicPages = ['Login'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem(AppConfig.key.LoggedIn);

  console.log('router.beforeEach')

  if (authRequired && !loggedIn) {
    next({name: 'Login'});
  } else {
    next();
  }
});

export default router
